import React from 'react';
import { FormattedMessage } from 'react-intl'
import banner from './AtlasBannerLogo.png';
import styles from './index.module.css'
import bannerBg from './bannerBg.png'
import bannerBgPhone from './bannerBgPhone.png'

const Slogan = () => (
  <div style={{position: 'relative'}}>
    <img className={styles.imgBg} src={bannerBg} alt="background" />
    <img className={styles.imgBgPhone} src={bannerBgPhone} alt="background" />
    <div className={styles.wrapper}>
      <img className={styles.logo} src={banner} alt="atlas banner" />
      <h3 className={styles.slogan}>
        <FormattedMessage id='protocol.protocol' />
        <span className={styles.hr}> - </span>
        <FormattedMessage id='protocol.slogan' />
      </h3>
      <div style={{ marginBottom: 30 }}>
        <a
          className={styles.download}
          href="/docs/atlas-protocol-light-paper.pdf"
          target="blank"
        >
          <FormattedMessage id='protocol.lightpaper' /> >
        </a>

        <a
          className={styles.download}
          href="https://docs.google.com/forms/d/e/1FAIpQLSdoNBe9VjamaNGVJ99KrOao4EZkdFfkH-lTuK-RZ8vTwUYejQ/viewform"
          target="blank"
        >
          <FormattedMessage id='protocol.whitepaper' /> >
        </a>
      </div>
    </div>
  </div>
);

export default Slogan;
