import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl'
import styles from './index.module.css'

const Roadmap = ({ intl: { locale } }) => (
  <div className={styles.wrapper}>
    <h2 style={{ fontSize: 36, fontWeight: 500 }}><FormattedMessage id='protocol.roadmap' /></h2>
    <div className={styles.timelineWrapper} locale={locale}>
      <div className={styles.timelineItem} todo="true">
        <FormattedMessage id='protocol.roadmap6.1' />
        <br />
        <FormattedMessage id='protocol.roadmap6.2' />
      </div>
      <div className={styles.timelineItem}>
        <FormattedMessage id='protocol.roadmap5.1' />
        <span className={styles.line}> | </span>
        <FormattedMessage id='protocol.roadmap5.2' />
      </div>
      <div className={styles.timelineItem}>
        <FormattedMessage id='protocol.roadmap4.2' />
        <span className={styles.line}> | </span>
        <FormattedMessage id='protocol.roadmap4.1' />
      </div>
      <div className={styles.timelineItem}>
        <FormattedMessage id='protocol.roadmap3.1' />
        <span className={styles.line}> | </span>
        <FormattedMessage id='protocol.roadmap3.2' />
      </div>
      <div className={styles.timelineItem}>
        <FormattedMessage id='protocol.roadmap2.2' />
        <span className={styles.line}> | </span>
        <FormattedMessage id='protocol.roadmap2.1' />
      </div>
      <div className={styles.timelineItem}>
        <FormattedMessage id='protocol.roadmap1.1' />
        <span className={styles.line}> | </span>
        <FormattedMessage id='protocol.roadmap1.2' />
      </div>
    </div>
    {
      locale == 'en' && <div>

      </div>
    }
  </div>
);

export default injectIntl(Roadmap);
