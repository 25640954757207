import React from 'react'
import Slogan from '../components/Slogan'
import ProtocolOverview from '../components/ProtocolOverview'
import Roadmap from '../components/Roadmap'
import Layout from '../components/Layout'

const ProductPage = ({ pageContext: { locale }, location }) => (
  <Layout locale={locale} location={location}>
    <Slogan />
    <ProtocolOverview />
    <Roadmap />
  </Layout>
)

export default ProductPage
