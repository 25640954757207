import React from 'react';
import { FormattedMessage } from 'react-intl'
import TIE from './TIE@2x.png';
import ATR from './ATR@2x.png';
import UDI from './UDI@2x.png';
import styles from './index.module.css'

const ProtocolOverview = () => (
  <div className={styles.wrapper}>
    <h2><FormattedMessage id="protocol.overview" /></h2>

    <div className={styles.featureWrapper}>
      <img src={TIE} alt="Transaction Interactive Element" style={{ width: '340px' }} />
      <div className={styles.featureDesc}>
        <h3><FormattedMessage id="protocol.TIE" /></h3>
        <p><FormattedMessage id="protocol.TIE-desc" /></p>
      </div>
    </div>

    <div className={styles.featureWrapper}>
      <div className={styles.featureDesc}>
        <h3><FormattedMessage id="protocol.ATR" /></h3>
        <p><FormattedMessage id='protocol.ATR-desc' /></p>
      </div>
      <img src={ATR} alt="Atlas Targeting Rank" style={{ width: '340px' }} />
    </div>

    <div className={styles.featureWrapper}>
      <img src={UDI} alt="User Data Incentives" style={{ width: '340px' }} />
      <div className={styles.featureDesc}>
        <h3><FormattedMessage id='protocol.UDI' /></h3>
        <p><FormattedMessage id='protocol.UDI-desc' /></p>
      </div>
    </div>
  </div>
);

export default ProtocolOverview;
